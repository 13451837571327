import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo"

function Contact() {
  return (
    <Layout pageInfo={{ pageName: "contact" }} logo={<Logo />}>
      <SEO
        title="Contact Us"
        description="Get in touch with us! Fill out our contact form or find our phone and email information here. We'd love to hear from you and answer any questions you have."
      />

      <div class="container mt-5">
        <div class="row mx-auto">
          <div class="col-5 p-4">
            <StaticImage src="../images/Contact/VLE-CONTACT-US.webp" />
          </div>
          <div class="col-7 m-auto">
            <h3>We'd love to hear from you</h3>
            For general enquiries please email us at:
            <a href="mailto:piero@viglietti-le.co.za">
              {" "}
              piero@viglietti-le.co.za
            </a>
            <p className="mb-4">and we will direct you to the relevant person.</p>

          </div>
        </div>
        <div class="row mx-auto px-4">
          <iframe 
                className="mb-4"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13235.614472391166!2d18.7689915!3d-33.9693167!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc4dd10f66d423%3A0x203b7950e4b689eb!2sViglietti%20Lifestyle%20Estate!5e0!3m2!1sen!2suk!4v1684078799068!5m2!1sen!2suk" 
                width="100%" height="450" 
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
